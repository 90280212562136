import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import {
  closeCensusSection,
  getCensusBranches,
  getCensusDetails,
  getCensusListBranches,
  getCensusSectionResult,
  getCensusSectionResultsRejections,
  getCensusSectionSummary,
  getUnitCensusList,
  rejectCensusSectionResults,
  reopenCensusSection,
} from 'fetchers/censusFetchers'
import { getSection } from 'fetchers/sectionFetchers'
import { getBranch } from 'fetchers/branchFetchers'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from 'components/shadcn/ui/breadcrumb'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import useAuth from 'hooks/useAuth'
import { useMemo, useState } from 'react'
import { Table } from 'components/Atoms/Table'
import { Field } from '@headlessui/react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select'
import { Button } from 'components/shadcn/ui/button'
import { successToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { notificationErrors } from 'error-data/notification'
import { LockClosedIcon, LockOpen1Icon } from '@radix-ui/react-icons'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogHeader,
  DialogDescription,
} from 'components/shadcn/ui/dialog'
import { AxiosError } from 'axios'
import { IAxiosErrors } from 'types/axios-errors'
import { censusCreate } from 'error-data/census'
import { XMarkIcon } from '@heroicons/react/20/solid'
import RejectedMessagesAlert from './rejected-items/RejectedMessagesAlert'
import ChartHorizontal from '../../charts/ChartHorizontal'
import { toast } from 'sonner'
import CensusBreadcrumbs from 'components/Atoms/Breadcrumbs/CensusBreadcrumbs'

const CensusDashboardSectionSummary = () => {
  const [isHovered, setIsHovered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [isRejectLoading, setIsRejectLoading] = useState(false)
  const [censusSectionRejectDescription, setCensusSectionRejectDescription] = useState('')

  const { userToken } = useAuth()
  const { censusId, sectionId } = useParams()
  const navigate = useNavigate()

  const {
    data: censusSectionResult,
    isLoading: isCensusSectionResultLoading,
    error: censusSectionResultError,
    refetch: refetchCensusSectionResult,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusSectionResult, censusId, sectionId],
    queryFn: () => getCensusSectionResult(censusId ?? '', sectionId ?? ''),
    enabled: !!censusId && !!sectionId,
  })

  const {
    data: censusSectionSummary,
    isLoading: isCensusSectionSummaryLoading,
    error: censusSectionSummaryError,
    refetch: refetchCensusSectionSummary,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusSectionSummary, censusId, sectionId],
    queryFn: () => getCensusSectionSummary(censusId ?? '', sectionId ?? ''),
    enabled: !!censusId && !!sectionId,
  })

  const {
    data: censusMembersSectionRejectedData,
    isLoading: isCensusSectionRejectedLoading,
    error: censusSectionRejectedError,
    refetch: refetchCensusSectionRejected,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusRejectedMembersList, censusId, sectionId],
    queryFn: () => getCensusSectionResultsRejections(censusId ?? '', sectionId ?? ''),
    enabled: !!censusId && !!sectionId,
  })

  const getUnacknowledgedRejections = (rejections: any[] | null | undefined) => {
    if (!Array.isArray(rejections)) return []
    return rejections.filter((rejection: any) => !rejection.ackedAt && !rejection.ackedBy)
  }

  const { data: getCensusData, isLoading: isCensusDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.censusDetails, censusId],
    queryFn: () => getCensusDetails(censusId ?? ''),
    enabled: !isCensusSectionResultLoading,
  })

  const {
    data: sectionData,
    isLoading: isSectionDataLoading,
    error: sectionDataError,
  } = useQuery({
    queryKey: [userToken, queryKeys.branch, sectionId],
    queryFn: () => getSection(sectionId ?? ''),
    enabled: !isCensusSectionResultLoading,
  })

  const { data: branchData, isLoading: isBranchDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.region, sectionData?.parent?.id],
    queryFn: () => getBranch(sectionData?.parent?.id ?? ''),
    enabled: !isCensusSectionResultLoading,
  })

  const {
    data: censusBranchData,
    isPending: isCensusBranchDataPending,
    isLoading: isCensusBranchDataLoading,
    error: censusBranchDataError,
    refetch: refetchCensusBranchData,
  } = useQuery({
    queryKey: [userToken, queryKeys.CensusDashboardBranch, censusId],
    queryFn: () => getCensusBranches(censusId ?? '', sectionData?.parent?.id ?? ''),
    enabled: !isCensusSectionResultLoading && !isSectionDataLoading,
  })

  const { data: getUnitCensusListData, isLoading: isGetUnitCensusListDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.unitCensusList, sectionData?.parent?.id],
    queryFn: () => getUnitCensusList(sectionData?.parent?.id ?? '', 1, 100, ''),
    retry: 1,
  })

  console.log({ censusBranchData })
  console.log({ getUnitCensusListData })

  // console.log({getUnitCensusListData})
  // { "items": [ { "id": "01922d7e-c709-7be7-907a-134c38336a90", "name": "Styrta się pali", "status": "in_progress", "dateFrom": "2024-09-26", "dateTo": "2024-10-10" }, { "id": "1ef6f539-7549-61ec-a4a3-a73cbd2ae92e", "name": "Spis wszystkich oddziałów", "status": "completed", "dateFrom": "2024-09-10", "dateTo": "2025-06-29" }, { "id": "1ef6f538-9455-6744-87ef-f78dddffe63a", "name": "Spis wszystkich okręgów", "status": "in_progress", "dateFrom": "2024-09-10", "dateTo": "2024-12-31" } ], "pagination": { "currentPage": 1, "lastPage": 1, "count": 3, "countFiltered": null, "lastPageFiltered": null } }

  const handleCensusSectionSummaryHandle = (selectedSectionId: string) => {
    navigate(`/census/dashboard/${censusId}/section/${selectedSectionId}/summary`)
  }

  const handleCensusSelect = (selectedCensusId: string) => {
    navigate(`/census/dashboard/${selectedCensusId}`)
  }

  const translateCensusStatus = (status: string) => {
    switch (status) {
      case 'awaiting':
        return 'Oczekiwanie'
      case 'in_progress':
        return 'W trakcie'
      case 'completed':
        return 'Zakończony'
      case 'canceled':
        return 'Anulowany'
      default:
        return 'Brak statusu'
    }
  }

  const closeCensusSectionMutation = useMutation({
    mutationFn: async ({ censusID, sectionId }: { censusID: string; sectionId: string }) =>
      closeCensusSection(censusID, sectionId),
    onSuccess: () => {
      successToast('Spis sekcji został zamknięty.')
      setIsLoading(false)
      refetchCensusSectionResult()
    },
    onError: (error: any) => {
      mutationErrorHandler(error, notificationErrors.update, 'Nie udało się zamknąć spisu sekcji.')
      setIsLoading(false)
    },
  })

  const reopenCensusSectionMutation = useMutation({
    mutationFn: async ({ censusID, sectionId }: { censusID: string; sectionId: string }) =>
      reopenCensusSection(censusID, sectionId),
    onSuccess: () => {
      successToast('Spis sekcji został otwarty.')
      setIsLoading(false)
      refetchCensusSectionResult()
    },
    onError: (error: any) => {
      mutationErrorHandler(error, notificationErrors.update, 'Nie udało się otworzyć spisu sekcji.')
      setIsLoading(false)
    },
  })

  const handleSectionMutation = async () => {
    setIsLoading(true)
    try {
      // Determine the action: open or close
      const action = censusSectionResult?.status === 'completed' ? 'otworzyć' : 'zamknąć'

      // Ask for confirmation before proceeding
      const confirmed = window.confirm(`Czy jesteś pewien, że chcesz ${action} spis sekcji?`)
      if (!confirmed) {
        setIsLoading(false)
        return // Exit if the user clicks "Cancel"
      }

      if (censusSectionResult?.status === 'in_progress') {
        closeCensusSectionMutation.mutate({ censusID: censusId ?? '', sectionId: sectionId ?? '' })
      } else if (censusSectionResult?.status === 'completed') {
        reopenCensusSectionMutation.mutate({ censusID: censusId ?? '', sectionId: sectionId ?? '' })
      }
    } catch (error) {
      toast.warning(String(error))
      mutationErrorHandler(null, null, 'Nie udało się zmienić statusu spisu sekcji.')
      setIsLoading(false)
    }
  }

  const rejectSectionMutation = useMutation({
    mutationFn: (data: any) => rejectCensusSectionResults(data.censusId, data.sectionId, data.data),
    onSuccess: () => {
      successToast('Wyniki spisu zostały odrzucone')
      setIsRejectLoading(false) 
      setOpen(false)
      setCensusSectionRejectDescription('')
      refetchCensusSectionRejected()
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsRejectLoading(false)
      mutationErrorHandler(error, censusCreate.create, 'Nie udało się odrzucić wyników spisu')
    },
  })

  const submitHandler = (e: any) => {
    e.preventDefault()
    setIsRejectLoading(true)
    if (censusSectionRejectDescription.length < 5) {
      mutationErrorHandler(null, null, 'Komentarz musi mieć co najmniej 5 znaków.')
      setIsRejectLoading(false)
      return
    }

    // if section is not completed then allow to mutate
    if (censusSectionResult?.status !== 'completed') {
      rejectSectionMutation.mutate({
        censusId: censusId,
        sectionId: sectionId,
        data: {
          description: censusSectionRejectDescription,
        },
      })
    }
  }

  const censusMembersSectionColumns = useMemo(() => {
    return [
      {
        Header: 'Nr',
        accessor: 'number',
        Cell: ({ row }: any) => {
          return row.index + 1
        },
      },
      {
        Header: 'Imię i nazwisko',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.firstName + ' ' + row.original?.lastName
        },
      },
      {
        Header: 'Placówka',
        accessor: 'employment',
        Cell: ({ row }: any) => {
          // return translateInstitutionNameByID(row.original?.id)
        },
      },
      {
        Header: 'Stanowisko',
        accessor: 'position',
        Cell: ({ row }: any) => {
          // return translatePositionNameByID(row.original?.id)
        },
      },
    ]
  }, [])

  if (
    isCensusSectionResultLoading ||
    isCensusDataLoading ||
    isSectionDataLoading ||
    isBranchDataLoading ||
    isCensusBranchDataLoading
  ) {
    return (
      <div>
        <div className='mb-4 h-4 w-full max-w-lg animate-pulse rounded-md bg-slate-300'></div>
        <div className='flex justify-between'>
          <div className='mb-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300'></div>
          <div className='mb-4 h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300'></div>
        </div>
        <Card>
          <CardContent>
            <div className='my-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300'></div>
            <div className='mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300'></div>
            <div className='mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300'></div>
            <div className='h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300'></div>
          </CardContent>
        </Card>
      </div>
    )
  }

  return (
    <div className='space-y-6'>
      {Array.isArray(censusMembersSectionRejectedData?.items) &&
        getUnacknowledgedRejections(censusMembersSectionRejectedData.items).length > 0 && (
          <RejectedMessagesAlert
            rejectedItems={getUnacknowledgedRejections(censusMembersSectionRejectedData.items)}
            censusId={censusId ?? ''}
            sectionId={sectionId ?? ''}
            sectionName={sectionData?.name ?? ''}
            rejectedId={getUnacknowledgedRejections(censusMembersSectionRejectedData.items)[0].id}
            onRejectionConfirmed={refetchCensusSectionRejected} // Pass the refetch function
          />
        )}
      <CensusBreadcrumbs
        censusId={getCensusData?.id}
        censusName={getCensusData?.name}
        regionId={branchData?.parent?.id}
        regionName={branchData?.parent?.name}
        branchId={sectionData?.parent?.id}
        branchName={sectionData?.parent?.name}
        sectionId={sectionData?.id}
        sectionName={sectionData?.name}
      />

      <div className='grid grid-cols-1 gap-6 lg:grid-cols-3'>
        <Card>
          <CardHeader>
            <CardTitle className='text-2xl'>{censusSectionResult?.unit?.name}</CardTitle>
          </CardHeader>
          <CardContent className='space-y-4'>
            <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Status spisu
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {translateCensusStatus(censusSectionResult?.status)}
              </dd>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Data rozpoczęcia
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {new Date(getCensusData?.dateFrom).toLocaleDateString()}
              </dd>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Data zakończenia
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {new Date(getCensusData?.dateTo).toLocaleDateString()}
              </dd>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Data aktualizacji
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {new Date(censusSectionResult?.updatedAt.slice(0, 10)).toLocaleDateString()}
              </dd>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Autor aktualizacji
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {censusSectionResult?.updatedBy}
              </dd>
            </dl>
            <Button
              disabled={isLoading || isCensusSectionResultLoading}
              variant={censusSectionResult?.status === 'in_progress' ? 'destructive' : 'default'}
              onClick={handleSectionMutation}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isLoading ? <span className='loader'></span> : ''}
              {censusSectionResult?.status === 'in_progress'
                ? 'Zamknij spis Sekcji'
                : 'Otwórz spis Sekcji'}
              {censusSectionResult?.status === 'in_progress' ? (
                <>
                  {isHovered ? (
                    <LockClosedIcon className='ml-1 h-4 w-4' />
                  ) : (
                    <LockOpen1Icon className='ml-1 h-4 w-4' />
                  )}
                </>
              ) : (
                <>
                  {isHovered ? (
                    <LockOpen1Icon className='ml-1 h-4 w-4' />
                  ) : (
                    <LockClosedIcon className='ml-1 h-4 w-4' />
                  )}
                </>
              )}
            </Button>
          </CardContent>
        </Card>

        <Card className='flex flex-col'>
          <ChartHorizontal
            isSectionSummary={true}
            data={{
              balanceChangeIn: censusSectionResult?.balanceChangeIn,
              balanceChangeOut: censusSectionResult?.balanceChangeOut,
            }}
          />
          <div className='m-6 flex flex-1 items-end'>
            <Button onClick={() => setOpen(true)}>
              {isRejectLoading ? <span className='loader'></span> : ''}
              Odrzuć wyniki podsumowania
              <XMarkIcon className='ml-1 h-5 w-5 transition-all' />
            </Button>
          </div>
        </Card>
        <div className='grid gap-6'>
          <Card>
            <CardHeader>
              <CardTitle>Przełącz podsumowanie sekcji</CardTitle>
            </CardHeader>
            <CardContent className='space-y-4'>
              <Field className='flex flex-1 flex-col gap-2'>
                <Select onValueChange={handleCensusSectionSummaryHandle}>
                  <SelectTrigger className='w-full max-w-[300px]' id='censusSelect'>
                    <SelectValue placeholder='Wybierz sekcję' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup className='max-w-sm'>
                      {censusBranchData?.subresults.map((census: any) =>
                        census.unit.id === sectionData?.parent?.id ? (
                          <SelectItem
                            key={census.unit.id}
                            value={`${census.unit.id}/branch-as-section`}
                          >
                            Członkowie nieprzypisani do ogniska
                          </SelectItem>
                        ) : (
                          <SelectItem key={census.unit.id} value={census.unit.id}>
                            {census.unit.name}
                          </SelectItem>
                        ),
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </Field>
            </CardContent>
          </Card>

          {getUnitCensusListData?.items?.length > 0 && (
            <Card>
              <CardHeader>
                <CardTitle>Przełącz spis</CardTitle>
              </CardHeader>
              <CardContent className='space-y-4'>
                <Field className='flex flex-1 flex-col gap-2'>
                  <Select onValueChange={handleCensusSelect}>
                    <SelectTrigger className='w-full max-w-[300px]'>
                      <SelectValue placeholder='Wybierz spis' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup className='max-w-sm'>
                        {getUnitCensusListData?.items.map((census: any) =>
                          census.id === sectionData?.parent?.id ? (
                            <SelectItem key={census.id} value={`${census.id}/branch-as-section`}>
                              Członkowie nieprzypisani do ogniska
                            </SelectItem>
                          ) : (
                            <SelectItem key={census.id} value={census.id}>
                              {census.name}
                            </SelectItem>
                          ),
                        )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </Field>
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      <Card>
        <CardHeader className='pb-0'>
          <CardTitle className='text-2xl'>Lista osób</CardTitle>
        </CardHeader>
        <CardContent>
          {censusSectionResult?.membersList.length === 0 ? (
            <p className='pt-4 text-sm text-muted-foreground'>Brak osób w sekcji</p>
          ) : (
            <Table
              data={censusSectionResult?.membersList || []}
              columns={censusMembersSectionColumns}
            />
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader className='pb-0'>
          <CardTitle className='text-2xl'>Lista dodanych osób do sekcji</CardTitle>
        </CardHeader>
        <CardContent>
          {censusSectionResult?.membersInList.length === 0 ? (
            <p className='pt-4 text-sm text-muted-foreground'>Brak dodanych osób do sekcji</p>
          ) : (
            <Table
              data={censusSectionResult?.membersInList || []}
              columns={censusMembersSectionColumns}
            />
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader className='pb-0'>
          <CardTitle className='text-2xl'>Lista usuniętych osób z sekcji</CardTitle>
        </CardHeader>
        <CardContent>
          {censusSectionResult?.membersOutList.length === 0 ? (
            <p className='pt-4 text-sm text-muted-foreground'>Brak usuniętych osób w sekcji</p>
          ) : (
            <Table
              data={censusSectionResult?.membersOutList || []}
              columns={censusMembersSectionColumns}
            />
          )}
        </CardContent>
      </Card>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className='pb-4'>Odrzuć wyniki sekcji</DialogTitle>
            <DialogDescription></DialogDescription>
            <form onSubmit={submitHandler}>
              <div className='space-y-6'>
                <div>
                  <label
                    className='mb-1 block text-sm font-medium text-gray-700'
                    htmlFor='censusSectionRejectDescription'
                  >
                    Komentarz*
                  </label>
                  <textarea
                    required
                    id='censusSectionRejectDescription'
                    name='censusSectionRejectDescription'
                    value={censusSectionRejectDescription}
                    onChange={(e) => {
                      setCensusSectionRejectDescription(e.target.value)
                    }}
                    aria-rowspan={4}
                    className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
                    placeholder='Podaj powód odrzucenia wyników'
                    minLength={5}
                  ></textarea>
                </div>
                <Button variant='default' disabled={isRejectLoading}>
                  {isRejectLoading && <div className='loader'></div>}
                  Odrzuć wyniki sekcji
                </Button>
              </div>
            </form>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CensusDashboardSectionSummary
