import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Checkbox } from 'components/Atoms/Checkbox'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Modal } from 'components/Atoms/Modal'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { archiveTraining, getTrainingList } from 'fetchers/trainingFetchers'
import useAuth from 'hooks/useAuth'
import { useLastPage } from 'hooks/useLastPage'
import useSearch from 'hooks/useSearch'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { path } from 'constants/path'
import { errorQuery } from 'tools/queryHelpers'
import { IOption } from 'types/form'
import { successToast } from 'tools/ToastHelpers'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { mutationErrorHandler } from 'tools/errorHandler'
import { exportTrainingErrors } from 'error-data/training'
import { IAxiosErrors } from 'types/axios-errors'

const searchParams = {
  simpleParams: [],
  arrayParams: [],
}

const TrainingList = () => {
  const { userToken, unitContext, userLoaded } = useAuth()

  const [isModalOpen, setModalOpen] = useState(false)
  const [trainingId, setTrainingId] = useState<string | null>(null)
  const queryClient = useQueryClient()

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const confirmArchiveTrainingHandler = () => {
    if (trainingId) {
      archiveTrainingMutation.mutate(trainingId)
      closeModal()
    }
  }

  const archiveTrainingMutation = useMutation({
    mutationFn: (trainigId: string) => archiveTraining(trainigId),
    onSuccess: () => {
      successToast('Zarchiwizowano szkolenie.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingArchive] })
      navigate(path.training.list)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(`${item.property}: ${item.error}`)
      })
      mutationErrorHandler(
        error,
        exportTrainingErrors.create,
        'Nie udało się zarchiwizować szkolenia.',
      )
    },
  })

  const { filters, perPage, page, setLimit, changePage } = useSearch(searchParams)
  const id = unitContext?.id
  const { control } = useForm()
  const navigate = useNavigate()

  const {
    data: trainingList,
    isLoading: trainingListLoading,
    refetch: trainingListRefetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.trainingList, filters, perPage, page, id],
    queryFn: () => getTrainingList(page, perPage, filters),
    retry: errorQuery,
  })

  const lastPage = useLastPage(trainingList?.pagination, trainingListLoading)
  const generatedExportsList = trainingList?.items || []

  const translateType = (type: string) => {
    switch (type) {
      case 'on-site':
        return 'Stacjonarne'
      case 'online':
        return 'Online'
      default:
        return 'Nieznany'
    }
  }

  const translateArchiveStatus = (archived: boolean) => {
    return archived ? 'Tak' : 'Nie'
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa szkolenia',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.name
        },
      },
      {
        Header: 'Imię i nazwisko prowadzącego',
        accessor: 'trainerName',
        Cell: ({ row }: any) => {
          return row.original?.trainerName
        },
      },
      {
        Header: 'Lokalizacja',
        accessor: 'location',
        Cell: ({ row }: any) => {
          return row.original?.location
        },
      },
      {
        Header: 'Data rozpoczęcia',
        accessor: 'dateStart',
        Cell: ({ row }: any) => {
          return new Date(row.original?.dateStart).toISOString().split('T')[0].split('-').join('-')
        },
      },
      {
        Header: 'Godzina rozpoczęcia',
        accessor: 'hourStart',
        Cell: ({ row }: any) => {
          return row.original?.hourStart
        },
      },
      {
        Header: 'Typ szkolenia',
        accessor: 'type',
        Cell: ({ row }: any) => {
          return translateType(row.original?.type)
        },
      },
      {
        Header: 'Liczba uczestników',
        accessor: 'participantNumber',
        Cell: ({ row }: any) => {
          return row.original?.participantNumber
        },
      },
      {
        Header: 'Archiwalne',
        accessor: 'archived',
        Cell: ({ row }: any) => {
          return translateArchiveStatus(row.original?.archived)
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex gap-2'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz szczegóły szkolenia'
                onClick={() => navigate(`/training/${row.original?.id}`)}
              />
              <HoverIcon
                iconName='TrashIcon'
                title='Archiwizuj szkolenie'
                onClick={() => {
                  setTrainingId(row.original?.id)
                  openModal()
                  trainingListRefetch()
                }}
              />
            </div>
          )
        },
      },
    ],
    [],
  )

  const onLimitHandler = (e: IOption) => setLimit(e.value)
  if (!userLoaded) return <LoadingSection />

  return (
    <Card
      label='Lista szkoleń'
      actionsButton={[
        {
          label: 'Utwórz szkolenie',
          handleClick: () => navigate(path.training.create),
        },
      ]}
    >
      <div className='flex items-center justify-between gap-4'>
        {lastPage && (
          <>
            <div className='flex flex-col justify-end gap-4 md:flex-row'>
              <div className='flex items-center gap-4'>
                <Typography weight='semibold' className='text-sm'>
                  Liczba wierszy na stronę
                </Typography>
                <Select
                  handleSelect={onLimitHandler}
                  options={[
                    { label: '10', value: 10 },
                    { label: '20 ', value: 20 },
                  ]}
                />
              </div>
            </div>

            <span className='flex flex-col items-center gap-2 md:flex-row md:gap-4'>
              {lastPage > 1 && (
                <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
              )}
            </span>
          </>
        )}
      </div>
      {!trainingListLoading && (
        <>
          <Table maxColumnWidth='300px' columns={columns} data={generatedExportsList || []} />
        </>
      )}
      {trainingListLoading && <TableSkeleton />}
      {isModalOpen && (
        <div className='data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 flex items-center justify-center bg-black/80'>
          <div className='rounded bg-white p-6 shadow-lg'>
            <h2 className='mb-4 text-xl'>Potwierdzenie archiwizacji</h2>
            <p>Czy na pewno chcesz zarchiwizować to szkolenie?</p>
            <div className='mt-4 flex justify-end space-x-4'>
              <button onClick={closeModal} className='rounded bg-gray-300 px-4 py-2'>
                Anuluj
              </button>
              <button
                onClick={confirmArchiveTrainingHandler}
                className='rounded bg-red-500 px-4 py-2 text-white'
              >
                Archiwizuj
              </button>
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}

export default TrainingList
