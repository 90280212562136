import React, { useState } from 'react'
import { NotificationModal } from '../NotificationModal/NotificationModal'
import DOMPurify from 'dompurify'
import {
  BellIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { InfoCircledIcon } from '@radix-ui/react-icons'

type NotificationLevel =
  | 'emergency'
  | 'alert'
  | 'critical'
  | 'error'
  | 'warning'
  | 'notice'
  | 'info'
  | 'debug'

type ToastProps = {
  notification: {
    id: string
    header: string
    content: string
    readAt: string | null
    type: string
    level: NotificationLevel
    metadata: { censusId: string }
  }
  onMarkAsRead: (id: string) => void
}

const sanitizeHtml = (html: string) => {
  return { __html: DOMPurify.sanitize(html) }
}

const getNotificationColor = (level: NotificationLevel) => {
  switch (level) {
    case 'emergency':
    case 'alert':
    case 'critical':
    case 'error':
      return 'bg-red-50'
    case 'warning':
      return 'bg-yellow-50'
    case 'notice':
    case 'info':
    case 'debug':
      return 'bg-sky-50'
    default:
      return 'bg-gray-50'
  }
}

const getNotificationIcon = (level: NotificationLevel) => {
  switch (level) {
    case 'emergency':
    case 'alert':
    case 'critical':
    case 'error':
      return <XCircleIcon className='h-5 w-5 text-red-600' aria-hidden='true' />
    case 'warning':
      return <ExclamationTriangleIcon className='h-5 w-5 text-yellow-600' aria-hidden='true' />
    case 'notice':
    case 'info':
    case 'debug':
      return <InfoCircledIcon className='h-5 w-5 text-sky-600' aria-hidden='true' />
    default:
      return <CheckCircleIcon className='h-5 w-5 text-gray-600' aria-hidden='true' />
  }
}

export const Toast: React.FC<ToastProps> = ({ notification, onMarkAsRead }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    onMarkAsRead(notification.id)
  }

  const notificationColor = getNotificationColor(notification.level)
  const notificationIcon = getNotificationIcon(notification.level)

  return (
    <>
      <div className={`flex w-full rounded-md p-4 shadow ${notificationColor}`}>
        <div className='flex flex-1'>
          <div className='shrink-0'>{notificationIcon}</div>
          <div className='ml-3'>
            <h3
              className={`truncate max-w-72 text-sm font-medium ${
                notification.level.includes('error')
                  ? 'text-red-800'
                  : notification.level === 'warning'
                    ? 'text-yellow-800'
                    : 'text-sky-800'
              }`}
            >
              {notification.header}
            </h3>
            <div
              dangerouslySetInnerHTML={sanitizeHtml(notification.content)}
              className={`mt-1 max-w-72 truncate text-sm [&>p:first-child]:truncate [&>p:not(:first-child)]:hidden ${
                notification.level.includes('error')
                  ? 'text-red-700'
                  : notification.level === 'warning'
                    ? 'text-yellow-700'
                    : 'text-sky-700'
              }`}
            ></div>
            <div className='mt-2'>
              <button
                onClick={handleOpenModal}
                className={`rounded-md px-2 py-1.5 text-sm font-medium ${
                  notification.level.includes('error')
                    ? 'text-red-800 hover:bg-red-100'
                    : notification.level === 'warning'
                      ? 'text-yellow-800 hover:bg-yellow-100'
                      : 'text-sky-800 hover:bg-sky-100'
                }`}
              >
                Przeczytaj
              </button>
            </div>
          </div>
        </div>
      </div>
      <NotificationModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        notification={notification}
        onMarkAsRead={onMarkAsRead}
      />
    </>
  )
}
