import { Fragment } from 'react'
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import DOMPurify from 'dompurify'

type NotificationType = {
  id: string
  header: string
  content: string
  readAt: string | null
  type: string
  metadata: {
    censusId?: string // Making censusId optional
  }
}

type ToastProps = {
  notification: NotificationType
  onMarkAsRead: (id: string) => void
  isOpen: boolean
  handleClose: () => void
}

const sanitizeHtml = (html: string) => {
  return {
    __html: DOMPurify.sanitize(html),
  }
}

export const NotificationModal = ({ isOpen, handleClose, notification }: ToastProps) => {
  const navigate = useNavigate()
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={handleClose}>
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/25' />
        </TransitionChild>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <DialogPanel className='relative w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <DialogTitle as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  {notification.header}
                </DialogTitle>
                <button
                  type='button'
                  className='absolute right-2 top-2 text-gray-400 hover:text-gray-500'
                  onClick={handleClose}
                >
                  <span className='sr-only'>Close</span>
                  <XMarkIcon className='h-6 w-6' />
                </button>

                <div
                  className='mt-2 truncate text-sm text-gray-500'
                  dangerouslySetInnerHTML={sanitizeHtml(notification.content)}
                />
                {notification.type === 'census_started' && (
                  <div className='mt-4'>
                    <a
                      className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      href={`/census/${notification.metadata.censusId}/show`}
                    >
                      Przejdź do spisu
                    </a>
                  </div>
                )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
